/* @import url("https://fonts.googleapis.com/css?family=Overpass"); */
@import url("https://fonts.googleapis.com/css?family=Inter");

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* calender styles */
.MuiPickersCalendarHeader-dayLabel {
  color: #0da4ce !important;
}

.MuiPickersDay-daySelected {
  background-color: #097395 !important;
  color: #fff;
}
.MuiPickersCalendarHeader-switchHeader {
  padding: 0 5.3rem;
}

/* .Mui-selected {
  background-color: #0da4ce !important;
  color: #fff !important;
} */

/* navbar styles */

/* company details */

.company_details_textarea {
  min-width: 90%;
  max-width: 90%;
  max-height: 60vh;
  min-height: 9.375rem;
  padding: 1.12rem 1.15rem;
  border-radius: 0;
  border-color: #bdbdbd;
  overflow-y: auto !important;
  font-family: "Inter", sans-serif;
}

.company_details_textarea:focus {
  border-radius: 0;
  border-color: #bdbdbd;
}

.edit_description {
  min-width: 100%;
  max-width: 100%;
  max-height: 60vh;
  min-height: 20px;
  padding: 0.8rem 1.15rem;
  border-radius: 0;
  border-color: #bdbdbd;
  overflow-y: auto !important;
  font-family: "Inter", sans-serif;
  margin-bottom: 2%;
}

.edit_description:focus {
  border-radius: 0;
  border-color: #bdbdbd;
}

.textEditor_company_details {
  background: #fff;
  height: 100%;
}

/* 

images upload Css

*/

.imagesUpload {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.imagesUpload::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* rates text field css */
.ratesInput input[type="number"] {
  -moz-appearance: textfield;
}

.ratesInput input::-webkit-outer-spin-button,
.ratesInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* dashboard Rfq  requestType*/
.rfqRequestType {
  text-overflow: ellipsis;
  max-width: 207px;
  overflow: hidden;
  white-space: nowrap;
  /* padding-bottom: 15px; */
}
.rfqRequestType2 {
  text-overflow: ellipsis;
  max-width: 236px;
  overflow: hidden;
  white-space: nowrap;
  /* padding-bottom: 15px; */
}

/* disableScrollbar */

.disableScrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #0da4ce;
}

.disableScrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
:root {
  --primaryBlueColor: #0da4ce;
  --primaryGreyColor: #e5e5e5;
  --white: #ffffff;
  --secondaryWhite: #fafafa;
  --outlineColor: #4f4f4f;
  --modalHeadingColor: #828282;
  --modalSubHeadingColor: #444748;
  --modalBoxShadowColor: #e0e0e0;
  --buttonHover: #097390;
  --lineBorderColor: #e4e4e4;
  --mobileBackgroundColor: #e5e5e5;
  --secondaryPaperBody: #323639;
  --clientColor: #424546;
  --red: #eb5757;
  --bqscratchCon: #f8fafb;
  /* --btn-white-bg: #FAFAFA; */
}

.toolTipClass {
  max-width: 200px;
  overflow-wrap: break-word;
  display: block;
  @media (min-width: 600px) {
    max-width: 100px;
  }
  cursor: pointer;
  color: #fff;
}

.quillClass.remove_toolbar .ql-toolbar.ql-snow {
  display: none;
}

.quillClass.remove_toolbar .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #ccc;
}

.quillClass {
  margin-top: 14px;
}

.quillClass .ql-editor {
  min-height: 242px;
}

/* textarea class */

.textarea_field {
  /* padding: 1.12rem 1.15rem; */
  border-radius: 0;
  border-color: #bdbdbd;
  overflow-y: auto !important;
  font-family: "Inter", sans-serif;
}

.textarea_field::placeholder {
  font-size: 1rem;
}

.react-pdf__Page__canvas {
  padding: 0 1.5rem;
  margin: 0 auto;
  width: calc(100% - 2 * 1.5rem) !important;
  height: 100% !important;
}

.rfqRequestType {
  text-overflow: ellipsis;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
}

.company_details_textarea1 {
  min-width: 94%;
  max-width: 94%;
  max-height: 86px;
  min-height: 56px;
  padding: 1.12rem 1.15rem;
  border-radius: 0;
  /* border-color: #bdbdbd; */
  /* overflow-y: auto !important; */
  font-family: "Inter";
  border-color: #e0e0e0 !important;
  outline: none;
}
